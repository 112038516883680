
import { groupBy } from 'lodash';
import { Button } from './Button';
import socials from './socials.json';

const categories = ['video', 'live', 'shorts', 'audio', 'blog', 'chat', 'community'];
const items = groupBy(socials, 'category');
const hideLabels = false;
const hideCategories = false;

export const App = () => {
    return (
        <div id="app">
            <header>
                <h1>@DriftInSonance</h1>
                <p>everywhere</p>
            </header>
            <main>
                {categories.map((category) => (
                    <div key={category}>
                        {hideCategories ? null : <h3>{category}</h3>}
                        <div className="tiles">
                            {items[category].map((social) => (
                                <section key={social.url}>
                                    <Button
                                        onClick={() => window.open(social.url, '_blank')}
                                        className="primary-button"
                                    >
                                        <div className="social-button">
                                            <img width={64} height={64} src={social.image} alt={social.name} className={social.invert === true ? 'invert' : undefined} />
                                            {hideLabels ? null : <span>{social.name}</span>}
                                        </div>
                                    </Button>
                                </section>
                            ))}
                        </div>
                    </div>
                ))}
            </main>
            {/* <footer>
                &copy; {new Date().getFullYear()} Jason Barnes
            </footer> */}
        </div>
    )
}