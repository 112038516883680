import { ReactNode } from "react";

type Props = {
    children: ReactNode;
    onClick: () => void;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    className?: string;
};

export const Button = ({ children, onClick, type = 'button', disabled = false, className = '' }: Props) => {
    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className={`button ${className}`}
        >
            {children}
        </button>
    );
};